import React, { useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import Sidbar from '../Sidbar/Sidbar';
import Navbar from '../Nanbar/Navbar';

export default function Layout() {
  const token = localStorage.getItem("token");
  const [isExpanded, setIsExpanded] = useState(false);

  if (!token) {
    // If no token is present, navigate to the login page
    return <Navigate to="/login" />;
  } 
  
  // else if(token){
  //   return <Navigate to="/Sliders" />;
  // }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Navbar isExpanded={isExpanded} toggleExpand={toggleExpand} />
      <div className='container mt-4'>
        <div className='row'>
          <div className={`sidebar-transition ${isExpanded ? 'col-1 sidebar-collapsed' : 'col-2 sidebar-expanded'}`}>
            <Sidbar isExpanded={isExpanded} />
          </div>
          <div className={`content-transition ${isExpanded ? 'col-11 content-expanded' : 'col-10 content-collapsed'}`}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
