import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function View({id}) {
    const [data, setdata] = useState([])
    console.log(id)



    useEffect(() => {
        axios.get(`https://rasmaback.sahla-eg.com/api/admin/sliders/${id}`)
          .then(response => {
            setdata(response?.data?.data);
          console.log(response.data.data , "datadatadata");
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
  return <>
  
  <div className="d-flex mx-3 mt-4 mb-4">
    <p className='fw-bold'>القيمه</p>
    <p className='mx-4'>{data?.value}</p>
  </div>
  <div className="d-flex mx-3 mt-2 mb-2">
    <p className='fw-bold'>الاسم</p>
    <p className='mx-4'>{data?.name}</p>
  </div>

  
  </>
}
