import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

export default function Navbar({ isExpanded, toggleExpand }) {
  let navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate("/login");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
      <button onClick={toggleExpand} className="btn btn-color me-auto">
            {isExpanded ? <i className="fa-solid fa-bars"></i> : <i className="fa-solid fa-x"></i>}
          </button>
        <a className="navbar-brand" href="#">
          <img src={`${process.env.PUBLIC_URL}/Img/logonav.png`} alt="Rasma" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
        
          <form className="d-flex me-auto">
            <button className={`text-white bg-logout btn btn-sm bg-danger`}>
              <div onClick={handleLogout} className="text-center text-white w-100">
                تسجيل خروج
              </div>
            </button>
          </form>
        </div>
      </div>
    </nav>
  );
}
