import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Alert } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BallTriangle } from 'react-loader-spinner';

const ModelCreateSlider = ({ onClose }) => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!title || !image) {
      setError('Both title and image are required.');
      return;
    }

    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('image', image);

    axios.post('https://rasmaback.sahla-eg.com/api/admin/sliders', formData)
      .then(response => {
        setLoading(false);
        setData(response.data);
        console.log(data);

        onClose(); // Close the modal after successful submission
      })
      .catch(error => {
        setLoading(false);
        // toast.error('Error creating slider.111111');
        console.log(data);
        // setError('Error creating slider. The image size might be large.'); // تحديث حالة الخطأ
        console.error('Error creating slider:', error);
      });
  };
// console.log(data);
  useEffect(() => {
    if (data) {
      if (data.status === true) {
        toast.success("Slider created 000000successfully!");
      } else if (data.status === false) {
        toast.error('Error creating slider.111111');
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(error); // استدعاء toast.error عند وجود خطأ
    }
  }, [error]);

  return (
    <div>
      <Form onSubmit={handleSubmit} className='mx-4'>
        <Form.Group controlId="formTitle">
          <Form.Label className='pt-2'>العنوان</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter title"
            value={title}
            onChange={handleTitleChange}
            className='border border-black'
          />
        </Form.Group>
        <Form.Group controlId="formImage" className="mt-1">
          <Form.Label className='pt-4'>الصوره</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            className='border border-black'
            onChange={handleImageChange}
          />
        </Form.Group>

        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}

        <div className='mb-3'>
          <Button type="submit" className="mt-3" disabled={loading}>
            {loading ? (
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#4fa94d"
                ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            ) : 'حفظ'}
          </Button>
        </div>
      </Form>
      <ToastContainer />
    </div>
  );
};

export default ModelCreateSlider;
