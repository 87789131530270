import React from 'react'
import Login from './Login'

export default function Home() {
  return (
<>


<Login/>


</>
  )
}
