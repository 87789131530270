import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
// import ModeSliders from './ModeSliders';
// import ModelCreateSlider from './ModelCreateSlider';
// import UbdateDate from './UpdateDate';
import 'react-toastify/dist/ReactToastify.css';
import UbdateContact from './UbdateContact';
import View from './View';
// import UbdateSocials from './UbdateSocials';
const Contact = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setSelectedId(id);
    setShow(true);
  };


  const [sho, setSho] = useState(false);
  const [selectedI, setSelectedI] = useState(null);
  const handleClos = () => setSho(false);
  const handleSho = (id) => {
    setSelectedId(id);
    setSho(true);
  };

  const [sh, setSh] = useState(false);
  const [selected, setSelected] = useState(null);
  const handleClo = () => setSh(false);
  const handleSh = (id) => {
    setSelectedId(id);
    setSh(true);
  };

  useEffect(() => {
    axios.get('https://rasmaback.sahla-eg.com/api/admin/contact-us')
      .then(response => {
        setData(response.data?.data || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const columns = [
    {
      name: 'الاسم الاول',
      selector: row => row.first_name,
      sortable: true,
    },
    {
      name: 'الاسم الاخر',
      selector: row => row.last_name,
      sortable: true,
    },
    {
      name: ' البريد الاكتروني',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: '  الرسائل',
      selector: row => row.message,
      sortable: true,
    },
    {
      name: 'المعاملات',
      cell: row => (
   <>
   

   
   <Button variant="delete " className=' btn bg-success border text-white mx-2' size="sm" onClick={() => handleShow(row.id)}>
   <i class="fas fa-eye"></i>
        </Button>
        <Button variant="delete " className=' btn btn-danger' size="sm" onClick={() => handleSh(row.id)}>
   <i class="fa fa-trash" aria-hidden="true"></i>
        </Button>
        
        
        
        </>
      ),
      sortable: true,
    },
  ];

  return (
    <div className='mt-5'>
      <div>
      {/* <Button variant="delete " className=' btn btn-success mx-3' size="sm" onClick={() => handleSho()}>
          <i className="fa-solid fa-plus px-2"></i>
          اضافة
        </Button> */}
      </div>
      <DataTable
        columns={columns}
        data={data}
        selectableRows
        pagination
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className='d-flex justify-content-between'>
          <Modal.Title>       عرض البيانات</Modal.Title>
        </Modal.Header>
  <div className=" mx-4">
  <View id={selectedId} />
       
  </div>
        <Modal.Footer>
      
      
        </Modal.Footer>
      </Modal>
      {/* <View id={selectedId}>  </View> */}
        <Modal.Header closeButton className='d-flex justify-content-between'>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        {/* <ModelCreateSlider id={selectedId} /> */}
      
    



      <Modal show={sh} onHide={handleClo}>
        <Modal.Header closeButton className='d-flex justify-content-between'>
          <Modal.Title>  هل تريد الحذف  </Modal.Title>
        </Modal.Header>

<div className='d-flex justify-content-between mx-5'>
<UbdateContact id={selectedId} />
<Button variant="secondary" className='btnh-10 mt-4' onClick={handleClo}>
            Close
          </Button>
</div>

      </Modal>
    </div>
  );
};

export default Contact;
