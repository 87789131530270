import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, Alert } from 'react-bootstrap';

const UpdateDate = ({ onClose, id }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [number1, setNumber1] = useState('');
  const [description1, setDescription1] = useState('');
  const [number2, setNumber2] = useState('');
  const [description2, setDescription2] = useState('');
  const [number3, setNumber3] = useState('');
  const [description3, setDescription3] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    if (error) setError(null); // Clear error on new input
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    if (error) setError(null); // Clear error on new input
  };

  const handleNumber1Change = (e) => {
    setNumber1(e.target.value);
    if (error) setError(null); // Clear error on new input
  };

  const handleDescription1Change = (e) => {
    setDescription1(e.target.value);
    if (error) setError(null); // Clear error on new input
  };

  const handleNumber2Change = (e) => {
    setNumber2(e.target.value);
    if (error) setError(null); // Clear error on new input
  };

  const handleDescription2Change = (e) => {
    setDescription2(e.target.value);
    if (error) setError(null); // Clear error on new input
  };

  const handleNumber3Change = (e) => {
    setNumber3(e.target.value);
    if (error) setError(null); // Clear error on new input
  };

  const handleDescription3Change = (e) => {
    setDescription3(e.target.value);
    if (error) setError(null); // Clear error on new input
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    if (error) setError(null); // Clear error on new input
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!title || !description || !image) {
      setError('Title, description, and image are required.');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    const formData = new FormData();
    formData.append('name', title);
    formData.append('description', description);
    formData.append('number1', number1);
    formData.append('description1', description1);
    formData.append('number2', number2);
    formData.append('description2', description2);
    formData.append('number3', number3);
    formData.append('description3', description3);
    formData.append('image', image);
    formData.append('_method', "put");

    axios.post(`https://rasmaback.sahla-eg.com/api/admin/who-are-we/${id}`, formData)
      .then(response => {
        setLoading(false);
        setSuccessMessage('Data updated successfully!');
        console.log('Data updated:', response.data);
        onClose(); // Close the modal after successful submission
      })
      .catch(error => {
        setLoading(false);
        // setError('Failed to update data. Please try again.');
        console.error('Error updating data:', error);
      });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} className='mx-4'>
        <Form.Group controlId="formTitle">
          <Form.Label className='pt-2'>العنوان</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter title"
            className='w-100 border-black'
            value={title}
            onChange={handleTitleChange}
          />
        </Form.Group>
        <Form.Group controlId="formDescription" className="mt-3">
          <Form.Label className='pt-1'>الوصف</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter description"
            className='w-100 border-black'
            value={description}
            onChange={handleDescriptionChange}
          />
        </Form.Group>

        <Form.Group controlId="formNumber1" className="mt-3">
          <Form.Label className='pt-1'>رقم 1</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter number 1"
            className='w-100 border-black'
            value={number1}
            onChange={handleNumber1Change}
          />
        </Form.Group>

        <Form.Group controlId="formDescription1" className="mt-3">
          <Form.Label className='pt-1'>الوصف 1</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter description 1"
            className='w-100 border-black'
            value={description1}
            onChange={handleDescription1Change}
          />
        </Form.Group>

        <Form.Group controlId="formNumber2" className="mt-3">
          <Form.Label className='pt-1'>رقم 2</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter number 2"
            className='w-100 border-black'
            value={number2}
            onChange={handleNumber2Change}
          />
        </Form.Group>

        <Form.Group controlId="formDescription2" className="mt-3">
          <Form.Label className='pt-1'>الوصف 2</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter description 2"
            className='w-100 border-black'
            value={description2}
            onChange={handleDescription2Change}
          />
        </Form.Group>

        <Form.Group controlId="formNumber3" className="mt-3">
          <Form.Label className='pt-1'>رقم 3</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter number 3"
            className='w-100 border-black'
            value={number3}
            onChange={handleNumber3Change}
          />
        </Form.Group>

        <Form.Group controlId="formDescription3" className="mt-3">
          <Form.Label className='pt-1'>الوصف 3</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter description 3"
            className='w-100 border-black'
            value={description3}
            onChange={handleDescription3Change}
          />
        </Form.Group>

        <Form.Group controlId="formImage" className="mt-3">
          <Form.Label className='pt-1'>الصورة</Form.Label>
          <Form.Control
            type="file"
            className='w-100 border-black'
            onChange={handleImageChange}
          />
        </Form.Group>

        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        {successMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}

        <Button variant="primary" type="submit" className="mt-2 mb-3" disabled={loading}>
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </Form>
    </div>
  );
};

export default UpdateDate;
