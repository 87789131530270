import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, Alert, Toast } from 'react-bootstrap';

const CreateWork = ({ onClose }) => {
  const [name, setname] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handlenameChange = (e) => {
    setname(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name || !image) {
      setError('Both name and image are required.');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('image', image);

    axios.post('https://rasmaback.sahla-eg.com/api/admin/our-works', formData)
      .then(response => {
        setLoading(false);
        setSuccessMessage('Slider created successfully!');
        Toast("Slider created successfully!")
        console.log('Slider created:', response.data);
        onClose(); // Close the modal after successful submission
      })
      .catch(error => {
        setLoading(false);
        // setError('Failed to create slider. Please try again.');
        console.error('Error creating slider:', error);
      });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} className='mx-4'>
        <Form.Group controlId="formname">
          <Form.Label className='pt-2'>name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter name"
            value={name}
                    className='w-100 border-black'
            onChange={handlenameChange}
          />
        </Form.Group>

        <Form.Group controlId="formImage" className="mt-3">
          <Form.Label className='pt-4'>Image</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
               className='w-100 border-black'
            onChange={handleImageChange}
          />
        </Form.Group>

        {/* {error && <Alert variant="danger" className="mt-3">{error}</Alert>} */}
        {successMessage && <Alert variant="success" className="mt-2">{successMessage}</Alert>}

      <div className='mb-3'>
      <Button variant="primary" type="submit" className="mt-3 " disabled={loading}>
          {loading ? 'Saving...' : 'حفظ'}
        </Button>
      </div>
      </Form>
    </div>
  );
};

export default CreateWork;
