import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Form, Alert, ToastContainer, Toast } from 'react-bootstrap';

const UpdateDate = ({ onClose, id }) => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch existing data for the slider
    axios.get(`https://rasmaback.sahla-eg.com/api/admin/sliders/${id}`)
      .then(response => {
        const sliderData = response.data?.data;
        setTitle(sliderData?.title || '');
        setData(sliderData);
      })
      .catch(error => {
        console.error('Error fetching slider data:', error);
      });
  }, [id]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('title', title);
    if (image) {
      formData.append('image', image);
    }
    formData.append('_method', "put");

    try {
      const response = await axios.post(`https://rasmaback.sahla-eg.com/api/admin/sliders/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setLoading(false);
      setShowToast(true);
      console.log('Slider updated:', response.data);
      onClose(); 
    } catch (error) {
      setLoading(false);
      setError('Failed to update slider. Please try again.');
      console.error('Error updating slider:', error);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} className='mx-4'>
        <Form.Group controlId="formTitle">
          <Form.Label className='pt-2'>العنوان</Form.Label>
          <Form.Control
            type="text"
            className='border border-black'
            placeholder="العنوان"
            value={title}
            onChange={handleTitleChange}
          />
        </Form.Group>

        <Form.Group controlId="formImage" className="mt-3">
          <Form.Label className='pt-1'>الصوره</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            className='border border-black'
            onChange={handleImageChange}
          />
        </Form.Group>

        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}

        <Button variant="primary" type="submit" className="mt-2 mb-3" disabled={loading}>
          {loading ? 'Saving...' : 'حفظ'}
        </Button>
      </Form>

      <ToastContainer position="top-end" className="p-3">
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
          <Toast.Body className='bg-alert'>Slider updated successfully!</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default UpdateDate;
