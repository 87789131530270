import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Sidbar({ isExpanded }) {
  let { pathname } = useLocation();
  let navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate("/login");
  };

  return (
    <div className="bg-color">
      <div className={`text-decoration-none ${pathname === "/Sliders" ? "bg_dark_color" : ""}`}>
        <Link to={pathname === "/Sliders" ? "" : "Sliders"} className="text-decoration-none linkslider">
          <div className={`d-flex justify-content-between w-75 mx-auto   text-center p-3 ${pathname === "/Sliders" ? "bg_dark_color" : ""}`}>
            <span><i className="fa-solid fa-sliders"></i></span>
            {!isExpanded && <span class="mx-4">اسلايدر</span>}
          </div>
        </Link>
      </div>
      <div className={`text-decoration-none ${pathname === "/Services" ? "bg_dark_color" : ""}`}>
        <Link to={"Services"} className="text-decoration-none linkslider">
          <div className={`d-flex justify-content-between w-75 mx-auto   p-3 ${pathname === "/Services" ? "bg_dark_color" : ""}`}>
            <span><i className="fa fa-cog" aria-hidden="true"></i></span>
            {!isExpanded && <span class="mx-4">خدماتنا</span>}
          </div>
        </Link>
      </div>
      <div className={`text-decoration-none ${pathname === "/Works" ? "bg_dark_color" : ""}`}>
        <Link to={"Works"} className="text-decoration-none">
          <div className={`d-flex justify-content-between w-75 mx-auto  w p-3 ${pathname === "/Works" ? "bg_dark_color" : ""}`}>
            <span className="text-dark"><i className="fa-solid fa-briefcase"></i></span>
            {!isExpanded && <span class="mx-4 text-dark">اعمالنا</span>}
          </div>
        </Link>
      </div>
      <div className={`text-decoration-none ${pathname === "/Contact" ? "bg_dark_color" : ""}`}>
        <Link to={"Contact"} className="text-decoration-none linkslider">
          <div className={`d-flex justify-content-between w-75 mx-auto  w p-3 ${pathname === "/Contact" ? "bg_dark_color" : ""}`}>
            <span><i className="fa fa-address-book" aria-hidden="true"></i></span>
            {!isExpanded && <span class="">تواصل معنا</span>}
          </div>
        </Link>
      </div>
      <div className={`text-decoration-none ${pathname === "/socials" ? "bg_dark_color" : ""}`}>
        <Link to={"socials"} className="text-decoration-none linkslider">
          <div className={`d-flex justify-content-between w-75  w-100 p-3 ${pathname === "/socials" ? "bg_dark_color" : ""}`}>
            <span class="mx-3 f-flex"><i className="fas fa-share-alt mx-2"></i></span>
            {!isExpanded && <span className="ml-14">التواصل الاجتماعي</span>}
          </div>
        </Link>
      </div>
      <div className={`text-decoration-none ${pathname === "/WhoAreWe" ? "bg_dark_color" : ""}`}>
        <Link to={"WhoAreWe"} className="text-decoration-none linkslider">
          <div className={`d-flex justify-content-between w-75 mx-auto   p-3 ${pathname === "/WhoAreWe" ? "bg_dark_color" : ""}`}>
            <span><i className="fa-solid fa-users"></i></span>
            {!isExpanded && <span class="mx-3">من نحن</span>}
          </div>
        </Link>
      </div>
      {/* <div className={`text-decoration-none text-white bg-logout`}>
        <div onClick={handleLogout} className="text-decoration-none text-center text-white w-100 p-3">
          تسجيل دخول
        </div>
      </div> */}
    </div>
  );
}
